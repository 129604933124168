import { render, staticRenderFns } from "./PerseusRendererIndex.vue?vue&type=template&id=b19f6afc&scoped=true&v-if=itemId&"
import script from "./PerseusRendererIndex.vue?vue&type=script&lang=js&"
export * from "./PerseusRendererIndex.vue?vue&type=script&lang=js&"
import style0 from "./PerseusRendererIndex.vue?vue&type=style&index=0&id=b19f6afc&lang=scss&scoped=true&"
import style1 from "./PerseusRendererIndex.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b19f6afc",
  null
  
)

component.options.__file = "PerseusRendererIndex.vue"
export default component.exports